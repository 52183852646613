window.getRemainingTime = () => {
  var timerElement = document.getElementById('payment-timer');
  if (timerElement) {
    var timerStartedAtString = timerElement.getAttribute('data-initial-value');
    var upgradeInitiatedAt = timerElement.getAttribute('data-upgrade_initiated_at')
    var timerStartedAt = new Date(timerStartedAtString); // Parse the datetime string
    var currentTime = new Date();
    var timeDifference = currentTime - timerStartedAt;
    var oneHourInMilliseconds = 3600000;

    if (timeDifference < oneHourInMilliseconds) {
      var remainingTime = oneHourInMilliseconds - timeDifference;
      return Math.floor(remainingTime / 1000); // Convert to seconds
    } else if(upgradeInitiatedAt === 'false') {
      document.getElementById('payment-timer').style.display = 'none';
      $('#timer_confirmation_modal').modal("show");
      return null;
    } else if(upgradeInitiatedAt === 'true'){
      document.getElementById('payment-timer').style.display = 'none';
      $('#upgrade_timer_end_modal').modal("show");
    }
  }
  return null;
}

window.startTimer = (duration) => {
  var countdownElement = document.getElementById('timer-value');
  var endTime = Date.now() + duration * 1000;

  var interval = setInterval(function () {
    var remainingTime = Math.max(0, endTime - Date.now());
    var hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
    var minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
    var seconds = Math.floor((remainingTime / 1000) % 60);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    if (countdownElement) {
      countdownElement.textContent = hours + ":" + minutes + ":" + seconds;
    }
    var orderHasAmoountToPay = document.getElementById('charge_amount');
    if (remainingTime <= 0) {
      clearInterval(interval);
      if($("#payment-timer").attr("data-upgrade_initiated_at") === 'true'){
        $('#upgrade_timer_end_modal').modal("show");
      }
      else
        if(Number(orderHasAmoountToPay.value > 0)){
          $('#timer_confirmation_modal').modal("show");
        }
      }
  }, 1000);
}

document.addEventListener("DOMContentLoaded", function () {
  var remainingSeconds = getRemainingTime();
  if (remainingSeconds !== null) {
    startTimer(remainingSeconds);
  } else {
    // If no timer was found or it's already expired, start a new 1-hour timer
    startTimer(3600); // 1 hour in seconds
  }
  $('#discard-cart').on('click', function(e) {
      e.preventDefault();
      var orderHasAmoountToPay = document.getElementById('charge_amount');
      if (orderHasAmoountToPay && Number(orderHasAmoountToPay.value > 0))
        $('#timer_discard_modal').modal('show');
      else
        window.location.href = window.location.href.split("/edit")[0]
  });

});


const apiRequest = (url, type, successMessage, errorMessage, dashboard) => {
  $.ajax({
    url: url,
    type: type,
    success: function(response) {
      console.log(successMessage, response);
      if (dashboard){
        window.location.href = window.location.href.split("/edit")[0]
      }
      else
        location.reload();
    },
    error: function(xhr, status, error) {
      console.error(errorMessage, error);
      alert(errorMessage);
    }
  });
}

window.clearOwnerCart = (orderId, dashboard=false) => {
  $("#clear-cart-btn").prop("disabled", true);
  $("#reset-timer-btn").prop("disabled", true);
  apiRequest(`/family/service_orders/${orderId}/clear_cart`, 'DELETE', 'Cart has been cleared.', 'Failed to clear cart. Please try again.', dashboard);
}

window.resetPaymentTimer = (orderId) => {
  $("#clear-cart-btn").prop("disabled", true);
  $("#reset-timer-btn").prop("disabled", true);
  apiRequest(`/family/service_orders/${orderId}/reset_timer`, 'PATCH', 'Timer has been reset', 'Failed to reset the timer. Please try again.');
}

window.cancelOnDiscardCart = () => {
  $('#timer_discard_modal').modal("hide");
}

window.approveOnDiscardCart = (orderId) => {
  $('#timer_discard_modal').modal("hide");
  window.clearOwnerCart(orderId, true);
}

$(document).on("click", "#add_more_upgrade_initiated_at", function() {
  let order_id = $('#add_more_upgrade_initiated_at').attr('data-order-id');
  $.ajax({
    type: "get",
    url: `/family/service_orders/${order_id}/extend_upgrade_initiated_time`,
    success: function() {
      location.reload();
    },
    error: function() {
    }
  });  
})

$(document).on("click", "#cancel_upgrade_initiated", function() {
  let order_id = $('#cancel_upgrade_initiated').attr('data-order-id');
  $.ajax({
    type: "get",
    url: `/family/service_orders/${order_id}/cancel_upgrade_initiated`,
    success: function(response) {
    },
    error: function() {
    }
  });  
})